import {
	child,
	ref,
	set,
	get,
	update,
	remove,
	onValue,
	onDisconnect,
} from "firebase/database";
import type { DatabaseReference, Unsubscribe } from "firebase/database";
import { database } from "global/firebase";

export class ChatService {
	private chatId: string;
	private login: string;

	private chatRef: DatabaseReference;
	private chatStatusRef: DatabaseReference;
	private typingStatusRef: DatabaseReference;
	private unreadCountsRef: DatabaseReference;
	private unreadChatsRef: DatabaseReference;

	constructor(chatId: string, login: string) {
		const chatPath = "chat";

		this.chatId = chatId;
		this.login = login;

		this.chatRef = ref(database, chatPath);
		this.chatStatusRef = child(
			this.chatRef,
			`chatStatus/${this.chatId}/${this.login}`,
		);
		this.typingStatusRef = child(
			this.chatRef,
			`typingStatus/${this.chatId}/${this.login}`,
		);
		this.unreadCountsRef = child(this.chatRef, "unreadCounts");
		this.unreadChatsRef = child(this.chatRef, "unreadChats");
	}

	// chatStatus
	async updateChatStatus(isOnline: boolean): Promise<void> {
		await update(this.chatStatusRef, {
			online: isOnline,
			lastActive: Date.now(),
		});
	}

	onChatStatusChange(
		callback: (
			status: Record<string, { online: boolean; lastActive: number }>,
		) => void,
	): Unsubscribe {
		const statusRef = child(this.chatRef, `chatStatus/${this.chatId}`);
		return onValue(statusRef, (snapshot) => {
			callback(snapshot.val());
		});
	}

	// typingStatus
	async setTypingStatus(isTyping: boolean): Promise<void> {
		await set(this.typingStatusRef, isTyping);
	}

	onTypingStatusChange(
		callback: (typingStatus: Record<string, boolean>) => void,
	): Unsubscribe {
		const typingRef = child(this.chatRef, `typingStatus/${this.chatId}`);
		return onValue(typingRef, (snapshot) => {
			callback(snapshot.val() || {});
		});
	}

	// unreadCounts and unreadChats
	static onUnreadCounts(
		callback: (unreadCounts: number) => void,
		login: string,
	): Unsubscribe {
		const unreadRef = ref(database, `chat/unreadCounts/${login}`);
		return onValue(unreadRef, (snapshot) => {
			const value = snapshot.val();
			callback(value ? value : 0);
		});
	}

	static onUnreadChats(
		callback: (unreadChats: Record<string, boolean>) => void,
		login: string,
	): Unsubscribe {
		const unreadChatsRef = ref(database, `chat/unreadChats/${login}`);
		return onValue(unreadChatsRef, (snapshot) => {
			callback(snapshot.val() || {});
		});
	}

	async addUnreadCounts(login: string): Promise<void> {
		const unreadRef = child(this.unreadCountsRef, `${login}`);
		const unreadChatsRef = child(
			this.unreadChatsRef,
			`${login}/${this.chatId}`,
		);

		const snapshotChats = await get(unreadChatsRef);

		if (!snapshotChats.exists()) {
			const snapshot = await get(unreadRef);

			if (snapshot.exists()) {
				const count = snapshot.val();
				await set(unreadRef, count + 1);
			} else {
				await set(unreadRef, 1);
			}
			return set(unreadChatsRef, true);
		}
	}

	async reduceUnreadCounts(login: string): Promise<void> {
		const unreadRef = child(this.unreadCountsRef, `${login}`);
		const unreadChatsRef = child(
			this.unreadChatsRef,
			`${login}/${this.chatId}`,
		);

		const snapshotChats = await get(unreadChatsRef);

		if (snapshotChats.exists()) {
			const snapshot = await get(unreadRef);
			if (snapshot.exists()) {
				const count = snapshot.val() - 1;
				await set(unreadRef, count >= 0 ? count : 0);
			}
			return remove(unreadChatsRef);
		}
	}

	// subscribe
	subscribe() {
		onDisconnect(this.chatStatusRef).update({
			online: false,
			lastActive: Date.now(),
		});
		onDisconnect(this.typingStatusRef).remove();
	}

	unSubscribe() {
		onDisconnect(this.chatStatusRef).cancel();
		onDisconnect(this.typingStatusRef).cancel();
	}
}
